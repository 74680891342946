import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Modal,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import BasicInfo from "./Sections/basic-info"
import Pricing from "./Sections/pricing"
import Quantity from "./Sections/quantity"
import Shipping from "./Sections/shipping"
import HcExtraFields from "./Sections/hc-extra-fields"
import Subscriptions from "./Sections/subscriptions"
import OfferFallback from "./Sections/offer-fallback"
import SearchProducts from "../../../components/Common/SearchProducts"
import SubscriptionsByCountry from "./Sections/subscriptions-by-country"
import { callGetApi, callPutApi } from "../../../utils/api"
import {
  showErrorToastr,
  showSuccessToastr,
} from "../../../components/Common/toastr"
import * as Yup from "yup"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { useFormik } from "formik"
import "./add-offer.scss"

const EditOffer = () => {
  document.title = "Edit Offer"
  const history = useNavigate()
  const location = useLocation()
  const { offerId, funnelId } = useParams()
  const queryParams = new URLSearchParams(location.search)
  const shop = queryParams.get("shop")
  const [isOpen, setIsOpen] = useState(false)
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)
  const [
    isModalUpgradeAdditionalProductsOpen,
    setIsModalUpgradeAdditionalProductsOpen,
  ] = useState(false)
  const [isExtraOpen, setIsExtraOpen] = useState(false)
  const [isFallbackOpen, setIsFallbackOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [editOffer, setEditOffer] = useState(null)
  const [pricingTypes, setPricingTypes] = useState([])
  const [shippingTypes, setShippingTypes] = useState([])
  const [offerProduct, setOfferProduct] = useState([])
  const [extraProducts, setExtraProducts] = useState([])
  const [fallbackProducts, setFallbackProducts] = useState([])
  const [replacementProducts, setReplacementProducts] = useState([])
  const [upgradeProduct, setUpgradeProduct] = useState(null)
  const [upgradeAdditionalProducts, setUpgradeAdditionalProducts] = useState([])
  const [isReplacementProducts, setIsReplacementProducts] = useState(false)
  const [isUpgradedProduct, setIsUpgradedProduct] = useState(false)
  const [isUpgradeAdditionalProducts, setIsUpgradeAdditionalProducts] =
    useState(false)
  const [productDetailsIds, setProductDetailsIds] = useState([])
  const [extraProductsIds, setExtraProductsIds] = useState([])
  const [fallbackProductsIds, setFallbackProductsIds] = useState([])
  const [upgradeProductId, setUpgradeProductId] = useState("")
  const [upgradeAdditionalProductsIds, setUpgradeAdditionalProductsIds] =
    useState([])
  const [perks, setPerks] = useState([])
  const [shaperPerks, setShaperPerks] = useState([])
  const [allCountries, setAllCountries] = useState([])
  const [countryConfig, setCountryConfig] = useState({
    isOpen: false,
    country: "",
  })

  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      active: true,
      pricingTypeId: "1",
      pricingTypeValue: "0",
      displayQuantity: "Yes",
      limitQuantity: true,
      quantity: 1,
      shippingTypeId: "1",
      shippingValue: "0",
      bannerText: "",
      minStock: "",
      productFallbackUUID: "",
      approveButtonText: "",
      orderProcessingText: "",
      titleText: "",
      subtitleText: "",
      copyText: "",
      componentsConfig: "",
      componentsConfigAutoshipFallback: "",
      subscriptionType: "",
      subscriptionName: "",
      subscriptionName2: "",
      replacement_offers: "",
      extraProducts: "",
      fallbackProducts: "",
      upgrade_product_id: "",
      upgrade_additional_products: "",
      popupExperienceData: "",
      isPopupExperienceActive: true,
      subscriptionConfig: [],
    },
    validationSchema: Yup.object().shape({
      pricingTypeValue: Yup.string().when("pricingTypeId", {
        is: val => val === "2",
        then: Yup.string().matches(
          /(^$)|(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)\.(\.[0-9]{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/,
          "Enter percentage between 0-100"
        ),
      }),
    }),
    onSubmit: values => {
      try {
        values.componentsConfig && JSON.parse(values.componentsConfig)
      } catch (error) {
        showErrorToastr(`Components Config JSON validation failed`)
        return
      }

      try {
        values.componentsConfigAutoshipFallback &&
          JSON.parse(values.componentsConfigAutoshipFallback)
      } catch (error) {
        showErrorToastr(
          `Autoship Fallback Components Config JSON validation failed`
        )
        return
      }

      try {
        values.popupExperienceData && JSON.parse(values.popupExperienceData)
      } catch (error) {
        showErrorToastr(`Popup Experience Config JSON validation failed`)
        return
      }
      const idsForRepProducts = replacementProducts.map(item => item.id)
      const idsWithString = idsForRepProducts.join(", ")
      const idsForExtProducts = extraProducts.map(item => item.id)
      const idsForFallbackProducts = fallbackProducts.map(item => item.id)
      const idsWithExtString = idsForExtProducts.join(", ")
      const idsWithFallbackString = idsForFallbackProducts.join(", ")
      const idsForUpgradeAdditionalProducts = upgradeAdditionalProducts
        .map(item => item.id)
        .join(", ")
      let subscriptionName = ""
      let requiredPlanName = ""
      if (
        (values.subscriptionType === "standalone_club_shaperbox" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_club_optional_shaperbox" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_shaperbox_v1" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_shaperbox_v2" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_shaperbox_v3" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_club_vip_shaperbox" &&
          values.subscriptionName2)
      ) {
        subscriptionName = `${values.subscriptionName},${values.subscriptionName2}`
      } else if (values.subscriptionType === "standalone_shaperbox") {
        subscriptionName = values.subscriptionName
        requiredPlanName = values.subscriptionName2
      } else {
        subscriptionName = values.subscriptionName
      }
      let payload = {
        ...editOffer,
        active: values.active,
        pricingTypeId: values.pricingTypeId,
        pricingTypeValue: values.pricingTypeValue
          ? Number(values.pricingTypeValue)
          : null,
        displayQuantity: values.displayQuantity === "Yes",
        limitQuantity: values.limitQuantity,
        quantity: values.quantity,
        shippingTypeId: values.shippingTypeId,
        shippingValue:
          shippingTypes?.filter(el => values.shippingTypeId === el.id)?.[0]
            ?.name === "free"
            ? null
            : values.shippingValue
            ? Number(values.shippingValue)
            : null,
        bannerText: values.bannerText,
        minStock: values.minStock,
        productFallbackUUID: values.productFallbackUUID,
        approveButtonText: values.approveButtonText,
        orderProcessingText: values.orderProcessingText,
        titleText: values.titleText,
        subtitleText: values.subtitleText,
        copyText: values.copyText,
        componentsConfig: values.componentsConfig,
        componentsConfigAutoshipFallback:
          values.componentsConfigAutoshipFallback,
        subscriptionType: values.subscriptionType,
        subscriptionName: subscriptionName,
        requiredPlanName,
        extraProducts: idsWithExtString,
        fallbackAutoship: idsWithFallbackString,
        replacement_offers: idsWithString,
        upgrade_product_id: upgradeProduct?.id,
        upgrade_additional_products: idsForUpgradeAdditionalProducts,
        pricingType: pricingTypes?.filter(
          el => values.pricingTypeId === el.id
        )?.[0],
        shippingType: shippingTypes?.filter(
          el => values.shippingTypeId === el.id
        )?.[0],
        image: offerProduct?.[0]?.images?.[0]?.src,
        name: offerProduct?.[0]?.title,
        productImage: offerProduct?.[0]?.images?.[0]?.src,
        productName: offerProduct?.[0]?.title,
        productId: offerProduct?.[0]?.id,
        productImages: offerProduct?.[0]?.images,
        popupExperienceData: values.popupExperienceData,
        isPopupExperienceActive: values.isPopupExperienceActive,
        subscriptionConfig: values.subscriptionConfig,
      }
      if (offerProduct?.length > 0) {
        if (!values.subscriptionType || perks?.length > 0) {
          if (
            values.subscriptionType === "standalone_club_shaperbox" ||
            values.subscriptionType === "standalone_club_vip_shaperbox" ||
            values.subscriptionType === "standalone_club_optional_shaperbox" ||
            values.subscriptionType === "standalone_shaperbox_v1" ||
            values.subscriptionType === "standalone_shaperbox_v2" ||
            values.subscriptionType === "standalone_shaperbox_v3" ||
            values.subscriptionType === "standalone_shaperbox"
          ) {
            if (shaperPerks?.length === 0) {
              showErrorToastr(
                `Please get perks for the ${
                  values.subscriptionType === "standalone_shaperbox"
                    ? "Required Subscription"
                    : "Shaperbox Subscription"
                } first`
              )
            } else {
              setIsUpdateLoading(true)
              callPutApi(
                true,
                `/upselling/offers/${editOffer.id}?shop=${shop}`,
                payload,
                onGetUpdateOfferSuccess,
                onGetUpdateOfferFail
              )
            }
          } else {
            setIsUpdateLoading(true)
            callPutApi(
              true,
              `/upselling/offers/${editOffer.id}?shop=${shop}`,
              payload,
              onGetUpdateOfferSuccess,
              onGetUpdateOfferFail
            )
          }
        } else {
          showErrorToastr("Please get perks for the subscriptions first")
        }
      } else {
        showErrorToastr("Please Select the product")
      }
    },
  })

  const onGetUpdateOfferSuccess = () => {
    showSuccessToastr("Offer Updated Successfully!")
    setIsUpdateLoading(false)
    history(`/create-funnel?funnelId=${editOffer.funnelId}&shop=${shop}`)
  }

  const onGetUpdateOfferFail = () => {
    setIsUpdateLoading(false)
  }

  // load funnel for country
  const onGetFunnelDetailsSuccess = data => {
    if (data?.countryCodes) {
      setAllCountries(data?.countryCodes?.split(","))
    }
  }

  const onGetFunnelDetailsFail = () => {}

  const loadFunnelDetails = () => {
    callGetApi(
      true,
      `/upselling/funnels/${funnelId}?shop=${shop}`,
      onGetFunnelDetailsSuccess,
      onGetFunnelDetailsFail
    )
  }

  // load default offer
  const onGetEditOfferSuccess = data => {
    setEditOffer(data)
    if (data.name && data.productId) {
      setOfferProduct([
        {
          title: data.name,
          id: data.productId,
          shopifyId: data?.product?.vendor_product?.product_id,
          images: [
            {
              src: data.image,
            },
          ],
        },
      ])
    }
    if (data.replacement_offers) {
      setProductDetailsIds(data.replacement_offers?.split(","))
    }
    if (data.extraProducts) {
      setExtraProductsIds(data.extraProducts?.split(","))
    }
    if (data.fallbackAutoship) {
      setFallbackProductsIds(data.fallbackAutoship?.split(","))
    }
    if (data.upgrade_product_id) {
      setUpgradeProductId(data.upgrade_product_id)
    }
    if (data.upgrade_additional_products) {
      setUpgradeAdditionalProductsIds(
        data.upgrade_additional_products?.split(",")
      )
    }
    let subscriptionName = ""
    let subscriptionName2 = ""
    if (
      (data.subscriptionType === "standalone_club_shaperbox" &&
        data.subscriptionName?.includes(",")) ||
      (data.subscriptionType === "standalone_club_vip_shaperbox" &&
        data.subscriptionName?.includes(",")) ||
      (data.subscriptionType === "standalone_club_optional_shaperbox" &&
        data.subscriptionName?.includes(",")) ||
      (data.subscriptionType === "standalone_shaperbox_v1" &&
        data.subscriptionName?.includes(",")) ||
      (data.subscriptionType === "standalone_shaperbox_v2" &&
        data.subscriptionName?.includes(",")) ||
      (data.subscriptionType === "standalone_shaperbox_v3" &&
        data.subscriptionName?.includes(","))
    ) {
      subscriptionName = data.subscriptionName?.split(",")?.[0]?.trim()
      subscriptionName2 = data.subscriptionName?.split(",")?.[1]?.trim()
    } else if (data.subscriptionType === "standalone_shaperbox") {
      subscriptionName = data.subscriptionName
      subscriptionName2 = data?.requiredPlanName
    } else {
      subscriptionName = data.subscriptionName
    }

    validationType.setValues({
      active: data.active,
      pricingTypeId: data.pricingTypeId,
      pricingTypeValue: data.pricingTypeValue || "",
      displayQuantity: data.displayQuantity ? "Yes" : "No",
      limitQuantity: data.limitQuantity,
      quantity: data.quantity,
      shippingTypeId: data.shippingTypeId,
      shippingValue: data.shippingValue,
      bannerText: data.bannerText,
      minStock: data.minStock,
      productFallbackUUID: data.productFallbackUUID,
      approveButtonText: data.approveButtonText,
      orderProcessingText: data.orderProcessingText,
      titleText: data.titleText,
      subtitleText: data.subtitleText,
      copyText: data.copyText,
      componentsConfig: data.componentsConfig,
      componentsConfigAutoshipFallback: data.componentsConfigAutoshipFallback,
      subscriptionType: data.subscriptionType,
      subscriptionName: subscriptionName,
      subscriptionName2: subscriptionName2,
      replacement_offers: data.replacement_offers,
      upgrade_product_id: data.upgrade_product_id,
      upgrade_additional_products: data.upgrade_additional_products,
      popupExperienceData: data.popupExperienceData,
      isPopupExperienceActive: data.isPopupExperienceActive,
      subscriptionConfig: data.subscriptionConfig,
    })
    setIsLoading(false)
  }

  const onGetEditOfferFail = () => {
    setIsLoading(false)
  }

  const loadEditOffer = () => {
    setIsLoading(true)
    callGetApi(
      true,
      `/upselling/offers/${offerId}?shop=${shop}`,
      onGetEditOfferSuccess,
      onGetEditOfferFail
    )
  }

  // load pricing types
  const onGetProductDetailsSuccess = data => {
    setReplacementProducts(prevProducts => [...prevProducts, data])
  }

  const onGetProductDetailsFail = () => {}

  const loadProductDetails = id => {
    callGetApi(
      true,
      `/upselling/products/${id}`,
      onGetProductDetailsSuccess,
      onGetProductDetailsFail
    )
  }

  const onGetExtraProductsSuccess = data => {
    setExtraProducts(prevProducts => [...prevProducts, data])
  }

  const onGetExtraProductsFail = () => {}

  const loadExtraProducts = id => {
    callGetApi(
      true,
      `/upselling/products/${id}`,
      onGetExtraProductsSuccess,
      onGetExtraProductsFail
    )
  }

  const onGetFallbackProductsSuccess = data => {
    setFallbackProducts(prevProducts => [...prevProducts, data])
  }

  const onGetFallbackProductsFail = () => {}

  const loadFallbackProducts = id => {
    callGetApi(
      true,
      `/upselling/products/${id}`,
      onGetFallbackProductsSuccess,
      onGetFallbackProductsFail
    )
  }

  const onGetUpgradeProductSuccess = data => {
    setUpgradeProduct(data)
  }

  const onGetUpgradeProductFail = () => {}

  const loadUpgradeProduct = id => {
    callGetApi(
      true,
      `/upselling/products/${id}`,
      onGetUpgradeProductSuccess,
      onGetUpgradeProductFail
    )
  }

  const onGetUpgradeAdditionalProductDetailsSuccess = data => {
    setUpgradeAdditionalProducts(prevProducts => [...prevProducts, data])
  }

  const onGetUpgradeAdditionalProductDetailsFail = () => {}

  const loadUpgradeAdditionalProductDetails = id => {
    callGetApi(
      true,
      `/upselling/products/${id}`,
      onGetUpgradeAdditionalProductDetailsSuccess,
      onGetUpgradeAdditionalProductDetailsFail
    )
  }

  // load pricing types
  const onGetPricingTypesSuccess = data => {
    setPricingTypes(data)
  }

  const onGetPricingTypesFail = () => {}

  const loadPricingTypes = () => {
    callGetApi(
      true,
      `/upselling/offers/pricing-types?shop=${shop}`,
      onGetPricingTypesSuccess,
      onGetPricingTypesFail
    )
  }

  // load shipping types
  const onGetShippingTypesSuccess = data => {
    setShippingTypes(data)
  }

  const onGetShippingTypesFail = () => {}

  const loadShippingTypes = () => {
    callGetApi(
      true,
      `/upselling/offers/shipping-types?shop=${shop}`,
      onGetShippingTypesSuccess,
      onGetShippingTypesFail
    )
  }
  const returnSaveProducts = selectedProducts => {
    if (isReplacementProducts) {
      const duplicateProducts = [...selectedProducts, ...replacementProducts]
      const uniqueReplacementProducts = duplicateProducts.reduce((acc, obj) => {
        const existingProducts = acc.find(item => item.id === obj.id)
        if (!existingProducts) {
          acc.push(obj)
        }
        return acc
      }, [])
      setReplacementProducts(uniqueReplacementProducts)
    } else {
      setOfferProduct(selectedProducts)
    }
  }
  const returnSaveExtraProducts = selectedProducts => {
    const duplicateProducts = [...selectedProducts, ...extraProducts]
    const uniqueExtraProducts = duplicateProducts.reduce((acc, obj) => {
      const existingProducts = acc.find(item => item.id === obj.id)
      if (!existingProducts) {
        acc.push(obj)
      }
      return acc
    }, [])
    setExtraProducts(uniqueExtraProducts)
  }
  const returnSaveFallbackProducts = selectedProducts => {
    setFallbackProducts(selectedProducts)
  }

  const returnSaveUpgradeAdditionalProducts = selectedProducts => {
    if (isUpgradeAdditionalProducts) {
      const duplicateProducts = [
        ...selectedProducts,
        ...upgradeAdditionalProducts,
      ]
      const uniqueUpgradeAdditionalReplacementProducts =
        duplicateProducts.reduce((acc, obj) => {
          const existingProducts = acc.find(item => item.id === obj.id)
          if (!existingProducts) {
            acc.push(obj)
          }
          return acc
        }, [])
      setUpgradeAdditionalProducts(uniqueUpgradeAdditionalReplacementProducts)
    } else {
      setOfferProduct(selectedProducts)
    }
  }

  useEffect(() => {
    if (productDetailsIds?.length > 0) {
      productDetailsIds.forEach(id => loadProductDetails(id?.trim()))
    }
  }, [productDetailsIds])

  useEffect(() => {
    if (extraProductsIds?.length > 0) {
      extraProductsIds.forEach(id => loadExtraProducts(id?.trim()))
    }
  }, [extraProductsIds])

  useEffect(() => {
    if (fallbackProductsIds?.length > 0) {
      fallbackProductsIds.forEach(id => loadFallbackProducts(id?.trim()))
    }
  }, [fallbackProductsIds])

  useEffect(() => {
    if (upgradeProductId) {
      loadUpgradeProduct(upgradeProductId)
    }
  }, [upgradeProductId])

  useEffect(() => {
    if (upgradeAdditionalProductsIds?.length > 0) {
      upgradeAdditionalProductsIds.forEach(id =>
        loadUpgradeAdditionalProductDetails(id?.trim())
      )
    }
  }, [upgradeAdditionalProductsIds])

  useEffect(() => {
    loadShippingTypes()
    loadPricingTypes()
    loadFunnelDetails()
  }, [])

  useEffect(() => {
    if (offerId) {
      loadEditOffer()
    }
  }, [offerId])

  const returnSaveUpgradeProduct = selectedProducts => {
    if (isUpgradedProduct) {
      setUpgradeProduct(selectedProducts[0])
    } else {
      setOfferProduct(selectedProducts)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Funnels"} breadcrumbItem={"Edit Offer"} />
          <div>
            <div
              className="back-button"
              onClick={() => {
                history(`/create-funnel?funnelId=${funnelId}&shop=${shop}`)
              }}
            >
              <i className="bx bx-arrow-back"></i>
            </div>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validationType.handleSubmit()
                return false
              }}
            >
              {isLoading ? (
                <div className="spinner-loader">
                  <Spinner className="ms-2" color="primary" />
                </div>
              ) : (
                <Row>
                  <Col lg={12}>
                    <BasicInfo
                      validationType={validationType}
                      setIsOpen={setIsOpen}
                      offerProduct={offerProduct}
                      setIsReplacementProducts={setIsReplacementProducts}
                      setIsExtraOpen={setIsExtraOpen}
                      setExtraProducts={setExtraProducts}
                      extraProducts={extraProducts}
                    />
                  </Col>
                  <Col lg={12}>
                    <Pricing
                      validationType={validationType}
                      pricingTypes={pricingTypes}
                    />
                  </Col>
                  <Col lg={12}>
                    <Quantity validationType={validationType} />
                  </Col>
                  <Col lg={12}>
                    <OfferFallback validationType={validationType} />
                  </Col>
                  <Col lg={12}>
                    <Shipping
                      validationType={validationType}
                      shippingTypes={shippingTypes}
                    />
                  </Col>
                  <Col lg={12}>
                    <Subscriptions
                      validationType={validationType}
                      setIsOpen={setIsOpen}
                      setIsUpgradeModalOpen={setIsUpgradeModalOpen}
                      setIsModalUpgradeAdditionalProductsOpen={
                        setIsModalUpgradeAdditionalProductsOpen
                      }
                      setReplacementProducts={setReplacementProducts}
                      replacementProducts={replacementProducts}
                      setIsReplacementProducts={setIsReplacementProducts}
                      upgradeProduct={upgradeProduct}
                      setUpgradeProduct={setUpgradeProduct}
                      setIsUpgradedProduct={setIsUpgradedProduct}
                      setIsUpgradeAdditionalProducts={
                        setIsUpgradeAdditionalProducts
                      }
                      setUpgradeAdditionalProducts={
                        setUpgradeAdditionalProducts
                      }
                      upgradeAdditionalProducts={upgradeAdditionalProducts}
                      perks={perks}
                      setPerks={setPerks}
                      shaperPerks={shaperPerks}
                      setShaperPerks={setShaperPerks}
                      editOffer={editOffer}
                      shop={shop}
                      setIsFallbackOpen={setIsFallbackOpen}
                      setFallbackProducts={setFallbackProducts}
                      fallbackProducts={fallbackProducts}
                      allCountries={allCountries}
                      setCountryConfig={setCountryConfig}
                    />
                  </Col>
                  <Col lg={12}>
                    <HcExtraFields validationType={validationType} />
                  </Col>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <div>
                          <div className="d-flex flex-wrap gap-2 form-btn">
                            <Button
                              type="submit"
                              color="primary"
                              isLoading={true}
                            >
                              {isUpdateLoading ? (
                                <Spinner
                                  size={"sm"}
                                  className="mx-3 mb-0 mt-1"
                                />
                              ) : (
                                "Submit"
                              )}
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </Container>
        <Modal
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Add product
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SearchProducts
              setIsOpen={setIsOpen}
              returnSaveProducts={returnSaveProducts}
              multiProducts={isReplacementProducts}
            />
          </div>
        </Modal>

        <Modal
          isOpen={isUpgradeModalOpen}
          toggle={() => {
            setIsUpgradeModalOpen(!isUpgradeModalOpen)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="upgradeModalLabel">
              Add product to upgrade
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsUpgradeModalOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SearchProducts
              setIsOpen={setIsUpgradeModalOpen}
              returnSaveProducts={returnSaveUpgradeProduct}
              multiProducts={false}
            />
          </div>
        </Modal>

        <Modal
          isOpen={isModalUpgradeAdditionalProductsOpen}
          toggle={() => {
            setIsModalUpgradeAdditionalProductsOpen(
              !isModalUpgradeAdditionalProductsOpen
            )
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Add product
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsModalUpgradeAdditionalProductsOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SearchProducts
              setIsOpen={setIsModalUpgradeAdditionalProductsOpen}
              returnSaveProducts={returnSaveUpgradeAdditionalProducts}
              multiProducts={isUpgradeAdditionalProducts}
            />
          </div>
        </Modal>

        <Modal
          isOpen={isExtraOpen}
          toggle={() => {
            setIsExtraOpen(!isExtraOpen)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Add product
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsExtraOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SearchProducts
              setIsOpen={setIsExtraOpen}
              returnSaveProducts={returnSaveExtraProducts}
              multiProducts={true}
            />
          </div>
        </Modal>

        <Modal
          isOpen={isFallbackOpen}
          toggle={() => {
            setIsFallbackOpen(!isFallbackOpen)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Add product
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsFallbackOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SearchProducts
              setIsOpen={setIsFallbackOpen}
              returnSaveProducts={returnSaveFallbackProducts}
              multiProducts={false}
            />
          </div>
        </Modal>
        <Modal
          isOpen={countryConfig.isOpen}
          toggle={() => {
            setCountryConfig({
              isOpen: false,
              country: "",
            })
          }}
          size="lg"
        >
          <div className="modal-header">
            <h5 className="modal-title">
              Subscription - {countryConfig.country}
            </h5>
            <button
              type="button"
              className="close"
              onClick={() => {
                setCountryConfig({
                  isOpen: false,
                  country: "",
                })
              }}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SubscriptionsByCountry
              validationTypeCountry={validationType}
              shop={shop}
              country={countryConfig.country}
              setCountryConfig={setCountryConfig}
              data={
                validationType?.values?.subscriptionConfig?.filter(
                  cfg => cfg.country === countryConfig.country
                )?.[0]
              }
            />
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default EditOffer
