import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Label,
  Row,
  Input,
  Form,
  Table,
  Modal,
} from "reactstrap"
import { callGetApi } from "../../../../utils/api"
import AutoshipExtraFields from "./autoship-extra-fields"
import { useFormik } from "formik"
import SearchProducts from "../../../../components/Common/SearchProducts"
import { showErrorToastr } from "../../../../components/Common/toastr"

const Subscriptions = props => {
  const { shop, country, validationTypeCountry, setCountryConfig, data } = props
  const [allShippingTypes, SetAllShippingTypes] = useState([])
  const [selectedOption, setSelectedOption] = useState("")
  const [perks, setPerks] = useState([])
  const [shaperPerks, setShaperPerks] = useState([])
  const [replacementProducts, setReplacementProducts] = useState([])
  const [upgradeProduct, setUpgradeProduct] = useState(null)
  const [upgradeAdditionalProducts, setUpgradeAdditionalProducts] = useState([])
  const [fallbackProducts, setFallbackProducts] = useState([])
  const [isReplacementProducts, setIsReplacementProducts] = useState(false)
  const [isUpgradedProduct, setIsUpgradedProduct] = useState(false)
  const [isUpgradeAdditionalProducts, setIsUpgradeAdditionalProducts] =
    useState(false)
  const [isFallbackOpen, setIsFallbackOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)
  const [
    isModalUpgradeAdditionalProductsOpen,
    setIsModalUpgradeAdditionalProductsOpen,
  ] = useState(false)
  const [productDetailsIds, setProductDetailsIds] = useState([])
  const [fallbackProductsIds, setFallbackProductsIds] = useState([])
  const [upgradeProductId, setUpgradeProductId] = useState("")
  const [upgradeAdditionalProductsIds, setUpgradeAdditionalProductsIds] =
    useState([])

  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      componentsConfigAutoshipFallback: "",
      subscriptionType: "",
      subscriptionName: "",
      subscriptionName2: "",
      replacement_offers: "",
      extraProducts: "",
      fallbackProducts: "",
      upgrade_product_id: "",
      upgrade_additional_products: "",
      popupExperienceData: "",
      isPopupExperienceActive: true,
    },
    onSubmit: values => {
      try {
        values.componentsConfigAutoshipFallback &&
          JSON.parse(values.componentsConfigAutoshipFallback)
      } catch (error) {
        showErrorToastr(
          `Autoship Fallback Components Config JSON validation failed`
        )
        return
      }

      try {
        values.popupExperienceData && JSON.parse(values.popupExperienceData)
      } catch (error) {
        showErrorToastr(`Popup Experience Config JSON validation failed`)
        return
      }

      const idsForRepProducts = replacementProducts.map(item => item.id)
      const idsForFallbackProducts = fallbackProducts.map(item => item.id)
      const idsWithString = idsForRepProducts.join(", ")
      const idsWithFallbackString = idsForFallbackProducts.join(", ")
      const idsForUpgradeAdditionalProducts = upgradeAdditionalProducts
        .map(item => item.id)
        .join(", ")
      let subscriptionName = ""
      let requiredPlanName = ""
      if (
        (values.subscriptionType === "standalone_club_shaperbox" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_club_optional_shaperbox" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_shaperbox_v1" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_shaperbox_v2" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_shaperbox_v3" &&
          values.subscriptionName2) ||
        (values.subscriptionType === "standalone_club_vip_shaperbox" &&
          values.subscriptionName2)
      ) {
        subscriptionName = `${values.subscriptionName},${values.subscriptionName2}`
      } else if (values.subscriptionType === "standalone_shaperbox") {
        subscriptionName = values.subscriptionName
        requiredPlanName = values.subscriptionName2
      } else {
        subscriptionName = values.subscriptionName
      }
      let payload = {
        country,
        subscription_name: subscriptionName,
        subscription_type: values.subscriptionType,
        replacement_offers: idsWithString,
        required_plan_name: null,
        upgrade_product_id: upgradeProduct?.id,
        upgrade_additional_products: idsForUpgradeAdditionalProducts,
        popup_experience_data: values.popupExperienceData,
        fallback_autoship: idsWithFallbackString,
        components_config_autoship_fallback:
          values.componentsConfigAutoshipFallback,
        requiredPlanName,
      }
      if (!values.subscriptionType || perks?.length > 0) {
        if (
          values.subscriptionType === "standalone_club_shaperbox" ||
          values.subscriptionType === "standalone_club_vip_shaperbox" ||
          values.subscriptionType === "standalone_club_optional_shaperbox" ||
          values.subscriptionType === "standalone_shaperbox_v1" ||
          values.subscriptionType === "standalone_shaperbox_v2" ||
          values.subscriptionType === "standalone_shaperbox_v3" ||
          values.subscriptionType === "standalone_shaperbox"
        ) {
          if (shaperPerks?.length === 0) {
            showErrorToastr(
              `Please get perks for the ${
                values.subscriptionType === "standalone_shaperbox"
                  ? "Required Subscription"
                  : "Shaperbox Subscription"
              } first`
            )
          } else {
            const updatedConfigs = [
              ...(validationTypeCountry.values.subscriptionConfig || []),
            ]
            const existingIndex = updatedConfigs.findIndex(
              cfg => cfg.country === country
            )

            if (existingIndex !== -1) {
              updatedConfigs[existingIndex] = payload
            } else {
              updatedConfigs.push(payload)
            }
            validationTypeCountry.setFieldValue(
              "subscriptionConfig",
              updatedConfigs
            )
            setCountryConfig({
              isOpen: false,
              country: "",
            })
          }
        } else {
          const updatedConfigs = [
            ...(validationTypeCountry.values.subscriptionConfig || []),
          ]
          const existingIndex = updatedConfigs.findIndex(
            cfg => cfg.country === country
          )

          if (existingIndex !== -1) {
            updatedConfigs[existingIndex] = payload
          } else {
            updatedConfigs.push(payload)
          }
          validationTypeCountry.setFieldValue(
            "subscriptionConfig",
            updatedConfigs
          )
          setCountryConfig({
            isOpen: false,
            country: "",
          })
        }
      } else {
        showErrorToastr("Please get perks for the subscriptions first")
      }
    },
  })

  const onGetUpgradeAdditionalProductDetailsSuccess = data => {
    setUpgradeAdditionalProducts(prevProducts => [...prevProducts, data])
  }

  const onGetUpgradeAdditionalProductDetailsFail = () => {}

  const loadUpgradeAdditionalProductDetails = id => {
    callGetApi(
      true,
      `/upselling/products/${id}`,
      onGetUpgradeAdditionalProductDetailsSuccess,
      onGetUpgradeAdditionalProductDetailsFail
    )
  }

  const onGetUpgradeProductSuccess = data => {
    setUpgradeProduct(data)
  }

  const onGetUpgradeProductFail = () => {}

  const loadUpgradeProduct = id => {
    callGetApi(
      true,
      `/upselling/products/${id}`,
      onGetUpgradeProductSuccess,
      onGetUpgradeProductFail
    )
  }

  const onGetFallbackProductsSuccess = data => {
    setFallbackProducts(prevProducts => [...prevProducts, data])
  }

  const onGetFallbackProductsFail = () => {}

  const loadFallbackProducts = id => {
    callGetApi(
      true,
      `/upselling/products/${id}`,
      onGetFallbackProductsSuccess,
      onGetFallbackProductsFail
    )
  }

  const onGetProductDetailsSuccess = data => {
    setReplacementProducts(prevProducts => [...prevProducts, data])
  }

  const onGetProductDetailsFail = () => {}

  const loadProductDetails = id => {
    callGetApi(
      true,
      `/upselling/products/${id}`,
      onGetProductDetailsSuccess,
      onGetProductDetailsFail
    )
  }

  const returnSaveFallbackProducts = selectedProducts => {
    setFallbackProducts(selectedProducts)
  }

  const returnSaveUpgradeAdditionalProducts = selectedProducts => {
    if (isUpgradeAdditionalProducts) {
      const duplicateProducts = [
        ...selectedProducts,
        ...upgradeAdditionalProducts,
      ]
      const uniqueUpgradeAdditionalReplacementProducts =
        duplicateProducts.reduce((acc, obj) => {
          const existingProducts = acc.find(item => item.id === obj.id)
          if (!existingProducts) {
            acc.push(obj)
          }
          return acc
        }, [])
      setUpgradeAdditionalProducts(uniqueUpgradeAdditionalReplacementProducts)
    }
  }

  const returnSaveProducts = selectedProducts => {
    if (isReplacementProducts) {
      const duplicateProducts = [...selectedProducts, ...replacementProducts]
      const uniqueReplacementProducts = duplicateProducts.reduce((acc, obj) => {
        const existingProducts = acc.find(item => item.id === obj.id)
        if (!existingProducts) {
          acc.push(obj)
        }
        return acc
      }, [])
      setReplacementProducts(uniqueReplacementProducts)
    }
  }

  const returnSaveUpgradeProduct = selectedProducts => {
    if (isUpgradedProduct) {
      setUpgradeProduct(selectedProducts[0])
    }
  }

  const onGetAllShippingTypesSuccess = data => {
    SetAllShippingTypes(data || [])
  }

  const onGetAllShippingTypesFail = () => {}

  const loadAllShippingTypes = () => {
    const storeMap = {
      "the-spa-dr": "the-spa-dr",
      "the-spa-dr.myshopify.com": "the-spa-dr",
      shapermint: "shapermint",
      "shapermint.myshopify.com": "shapermint",
      revel: "revel",
      "revel.myshopify.com": "revel",
      "truekind-store": "truekind-store",
      "truekind-store.myshopify.com": "truekind-store",
    }
    Object.freeze(storeMap)
    const shopName = storeMap[shop] || shop
    callGetApi(
      true,
      `/upselling/subscription_types?store=${shopName}`,
      onGetAllShippingTypesSuccess,
      onGetAllShippingTypesFail
    )
  }

  const handleGetPerks = (value, type) => {
    let availablePerks = allShippingTypes?.filter(el => el.name === value)?.[0]
      ?.discounts
    if (type === "subscriptionName") {
      setPerks(availablePerks)
    } else {
      setShaperPerks(availablePerks)
    }
  }

  useEffect(() => {
    if (allShippingTypes?.length > 0) {
      if (data && validationType?.values?.subscriptionName) {
        handleGetPerks(
          validationType?.values?.subscriptionName,
          "subscriptionName"
        )
      }
      if (data && validationType?.values?.subscriptionName2) {
        handleGetPerks(
          validationType?.values?.subscriptionName2,
          "subscriptionName2"
        )
      }
    }
  }, [data, allShippingTypes])

  useEffect(() => {
    loadAllShippingTypes()
  }, [])

  useEffect(() => {
    if (replacementProducts?.length > 0) {
      setSelectedOption("replacement")
    } else if (upgradeProduct) {
      setSelectedOption("upgrade")
    }
  }, [replacementProducts, upgradeProduct])

  useEffect(() => {
    if (upgradeProductId) {
      loadUpgradeProduct(upgradeProductId)
    }
  }, [upgradeProductId])

  useEffect(() => {
    if (fallbackProductsIds?.length > 0) {
      fallbackProductsIds.forEach(id => loadFallbackProducts(id?.trim()))
    }
  }, [fallbackProductsIds])

  useEffect(() => {
    if (productDetailsIds?.length > 0) {
      productDetailsIds.forEach(id => loadProductDetails(id?.trim()))
    }
  }, [productDetailsIds])

  useEffect(() => {
    if (upgradeAdditionalProductsIds?.length > 0) {
      upgradeAdditionalProductsIds.forEach(id =>
        loadUpgradeAdditionalProductDetails(id?.trim())
      )
    }
  }, [upgradeAdditionalProductsIds])

  useEffect(() => {
    if (data) {
      if (data.replacement_offers) {
        setProductDetailsIds(data.replacement_offers?.split(","))
      }
      if (data.fallback_autoship) {
        setFallbackProductsIds(data.fallback_autoship?.split(","))
      }
      if (data.upgrade_product_id) {
        setUpgradeProductId(data.upgrade_product_id)
      }
      if (data.upgrade_additional_products) {
        setUpgradeAdditionalProductsIds(
          data.upgrade_additional_products?.split(",")
        )
      }
      let subscriptionName = ""
      let subscriptionName2 = ""
      if (
        (data.subscription_type === "standalone_club_shaperbox" &&
          data.subscription_name?.includes(",")) ||
        (data.subscription_type === "standalone_club_vip_shaperbox" &&
          data.subscription_name?.includes(",")) ||
        (data.subscription_type === "standalone_club_optional_shaperbox" &&
          data.subscription_name?.includes(",")) ||
        (data.subscription_type === "standalone_shaperbox_v1" &&
          data.subscription_name?.includes(",")) ||
        (data.subscription_type === "standalone_shaperbox_v2" &&
          data.subscription_name?.includes(",")) ||
        (data.subscription_type === "standalone_shaperbox_v3" &&
          data.subscription_name?.includes(","))
      ) {
        subscriptionName = data.subscription_name?.split(",")?.[0]?.trim()
        subscriptionName2 = data.subscription_name?.split(",")?.[1]?.trim()
      } else if (data.subscription_type === "standalone_shaperbox") {
        subscriptionName = data.subscription_name
        subscriptionName2 = data?.requiredPlanName
      } else {
        subscriptionName = data.subscription_name
      }
      validationType.setValues({
        componentsConfigAutoshipFallback:
          data.components_config_autoship_fallback,
        subscriptionType: data.subscription_type,
        subscriptionName: subscriptionName,
        subscriptionName2: subscriptionName2,
        replacement_offers: data.replacement_offers,
        upgrade_product_id: data.upgrade_product_id,
        upgrade_additional_products: data.upgrade_additional_products,
        popupExperienceData: data.popup_experience_data,
        isPopupExperienceActive: data.isPopupExperienceActive,
      })
    }
  }, [data])

  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validationType.handleSubmit()
          return false
        }}
      >
        <div>
          <Row>
            <Col lg={12}>
              <Card
                style={{
                  marginBottom: "0px",
                  boxShadow: "none",
                }}
              >
                <CardBody
                  style={{
                    padding: "0px 10px 15px",
                  }}
                >
                  <CardTitle>Subscription</CardTitle>
                  <div>
                    <div className="mb-3">
                      <Label className="form-label">Subscription Type</Label>
                      <select
                        name="subscriptionType"
                        className="form-select"
                        onChange={e => {
                          setPerks([])
                          setShaperPerks([])
                          validationType.setFieldValue("subscriptionName", "")
                          validationType.setFieldValue("subscriptionName2", "")
                          validationType.setFieldValue(
                            "subscriptionType",
                            e.target.value
                          )
                        }}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.subscriptionType}
                      >
                        <option value="">No</option>
                        <option value="standalone_club">StandaloneClub</option>
                        <option value="standalone_club_v2">
                          StandaloneClubV2
                        </option>
                        <option value="standalone_club_v3">
                          StandaloneClubV3
                        </option>
                        <option value="standalone_club_v4">
                          StandaloneClubV4
                        </option>
                        <option value="standalone_truekind_elite">
                          StandaloneTruekindElite
                        </option>
                        <option value="standalone_shaperbox">
                          StandaloneShaperbox
                        </option>
                        <option value="standalone_club_vip_shaperbox">
                          ClubValuePropBox
                        </option>
                        <option value="standalone_shaperbox_plus_v1">
                          StandaloneShaperboxPlusV1
                        </option>
                        <option value="standalone_shaperbox_plus_v2">
                          StandaloneShaperboxPlusV2
                        </option>
                        <option value="standalone_shaperbox_v1">
                          StandaloneShaperboxV1
                        </option>
                        <option value="standalone_shaperbox_v2">
                          StandaloneShaperboxV2
                        </option>
                        <option value="standalone_shaperbox_v3">
                          StandaloneShaperboxV3
                        </option>
                        <option value="standalone_club_shaperbox">
                          StandaloneClubShaperbox
                        </option>
                        <option value="standalone_club_optional_shaperbox">
                          StandaloneClubOptionalShaperbox
                        </option>
                        <option value="embedded">Embedded</option>
                        <option value="autoship">Autoship</option>
                        <option value="autoship_v2">AutoshipV2</option>
                      </select>
                    </div>
                    {!!validationType.values.subscriptionType && (
                      <div className="mb-3">
                        <Label className="form-label">
                          {(validationType.values.subscriptionType ===
                            "standalone_club_shaperbox" ||
                            validationType.values.subscriptionType ===
                              "standalone_club_optional_shaperbox" ||
                            validationType.values.subscriptionType ===
                              "standalone_shaperbox_v1" ||
                            validationType.values.subscriptionType ===
                              "standalone_shaperbox_v2" ||
                            validationType.values.subscriptionType ===
                              "standalone_shaperbox_v3" ||
                            validationType.values.subscriptionType ===
                              "standalone_club_vip_shaperbox") &&
                            "Club "}
                          {(validationType.values.subscriptionType ===
                            "standalone_shaperbox_plus_v1" ||
                            validationType.values.subscriptionType ===
                              "standalone_shaperbox_plus_v2") &&
                            "Shaperbox "}
                          Subscription Name (Subscription Plan)
                        </Label>
                        <select
                          name="subscriptionName"
                          className="form-select"
                          onChange={e => {
                            handleGetPerks(e.target.value, "subscriptionName")
                            validationType.setFieldValue(
                              "subscriptionName",
                              e.target.value
                            )
                          }}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.subscriptionName}
                        >
                          <option value="">Select Subscription Name</option>
                          {allShippingTypes?.map(el => {
                            return (
                              <option value={el.name}>
                                {el.name} - {el.title}
                              </option>
                            )
                          })}
                        </select>
                        {perks?.length > 0 && (
                          <div style={{ marginTop: 10 }}>
                            <div className="table-responsive">
                              <Table className="table table-bordered mb-0 funnel-table">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Discount Value</th>
                                    <th>Discount Type</th>
                                    <th>Discount Target Selection</th>
                                    <th>Discount Target</th>
                                    <th>Discount Lines</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {perks?.map((perk, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{perk.name}</td>
                                        <td>
                                          {perk?.discount_type === "percentage"
                                            ? `${perk.discount_value}%`
                                            : perk.discount_value}
                                        </td>
                                        <td>{perk.discount_type}</td>
                                        <td>
                                          {perk.discount_target_selection}
                                        </td>
                                        <td>{perk.discount_target}</td>
                                        <td>{perk.discount_lines}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {(validationType.values.subscriptionType ===
                      "standalone_club_shaperbox" ||
                      validationType.values.subscriptionType ===
                        "standalone_shaperbox" ||
                      validationType.values.subscriptionType ===
                        "standalone_club_optional_shaperbox" ||
                      validationType.values.subscriptionType ===
                        "standalone_shaperbox_v1" ||
                      validationType.values.subscriptionType ===
                        "standalone_shaperbox_v2" ||
                      validationType.values.subscriptionType ===
                        "standalone_shaperbox_v3" ||
                      validationType.values.subscriptionType ===
                        "standalone_club_vip_shaperbox") && (
                      <div className="mb-3">
                        <Label className="form-label">
                          {validationType.values.subscriptionType ===
                          "standalone_shaperbox"
                            ? "Required"
                            : "Shaperbox"}{" "}
                          Subscription Name (Subscription Plan)
                        </Label>
                        <select
                          name="subscriptionName2"
                          className="form-select"
                          onChange={e => {
                            handleGetPerks(e.target.value, "subscriptionName2")
                            validationType.setFieldValue(
                              "subscriptionName2",
                              e.target.value
                            )
                          }}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.subscriptionName2}
                        >
                          <option value="">Select Subscription Name</option>
                          {allShippingTypes?.map(el => {
                            return (
                              <option value={el.name}>
                                {el.name} - {el.title}
                              </option>
                            )
                          })}
                        </select>
                        {shaperPerks?.length > 0 && (
                          <div style={{ marginTop: 10 }}>
                            <div className="table-responsive">
                              <Table className="table table-bordered mb-0 funnel-table">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Discount Value</th>
                                    <th>Discount Type</th>
                                    <th>Discount Target Selection</th>
                                    <th>Discount Target</th>
                                    <th>Discount Lines</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {shaperPerks?.map((perk, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{perk.name}</td>
                                        <td>
                                          {perk?.discount_type === "percentage"
                                            ? `${perk.discount_value}%`
                                            : perk.discount_value}
                                        </td>
                                        <td>{perk.discount_type}</td>
                                        <td>
                                          {perk.discount_target_selection}
                                        </td>
                                        <td>{perk.discount_target}</td>
                                        <td>{perk.discount_lines}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {(validationType.values.subscriptionType === "autoship" ||
                      validationType.values.subscriptionType ===
                        "autoship_v2") && (
                      <div className="mb-3">
                        <Label className="form-label">
                          Select Autoship Fallback Product
                        </Label>
                        {fallbackProducts?.length > 0 && (
                          <div>
                            {fallbackProducts?.map((product, index) => {
                              return (
                                <div
                                  className="selected-product-item"
                                  key={index}
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <div>
                                    <img src={product.images?.[0]?.src} />
                                    <Label className="form-label">
                                      {product.title}
                                    </Label>
                                  </div>
                                  <i
                                    className="mdi mdi-delete"
                                    style={{ fontSize: 23, cursor: "pointer" }}
                                    onClick={() => {
                                      setFallbackProducts(fallbackProducts_ => {
                                        const updatedProducts =
                                          fallbackProducts_
                                            ? fallbackProducts_.filter(
                                                el => el.id !== product.id
                                              )
                                            : []
                                        return updatedProducts
                                      })
                                    }}
                                  ></i>
                                </div>
                              )
                            })}
                          </div>
                        )}
                        <div className="offer-product-container">
                          <Button
                            color="primary"
                            type="button"
                            onClick={() => {
                              setIsFallbackOpen(true)
                            }}
                          >
                            Select A Product
                          </Button>
                        </div>
                        <div className="mb-3 mt-3">
                          <Label className="form-label">
                            Autoship Fallback Components Config
                          </Label>
                          <Input
                            name="componentsConfigAutoshipFallback"
                            type="textarea"
                            rows="5"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={
                              validationType.values
                                .componentsConfigAutoshipFallback
                            }
                          />
                        </div>
                      </div>
                    )}
                    {(validationType.values.subscriptionType === "autoship" ||
                      validationType.values.subscriptionType ===
                        "autoship_v2") && (
                      <>
                        <div className="mb-3">
                          <Label className="form-label">
                            Select Product Type
                          </Label>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              id="replacementProduct"
                              name="productOption"
                              value="replacement"
                              className="form-check-input"
                              checked={selectedOption === "replacement"}
                              onChange={e => setSelectedOption(e.target.value)}
                              disabled={!!upgradeProduct}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="replacementProduct"
                            >
                              Replacement Product
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              id="upgradeProduct"
                              name="productOption"
                              value="upgrade"
                              className="form-check-input"
                              checked={selectedOption === "upgrade"}
                              onChange={e => setSelectedOption(e.target.value)}
                              disabled={!!replacementProducts?.length}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="upgradeProduct"
                            >
                              Upgrade to Autoship
                            </label>
                          </div>
                        </div>
                        {selectedOption === "replacement" &&
                          replacementProducts?.length > 0 && (
                            <div className="mb-3 mt-4">
                              {replacementProducts?.length > 0 && (
                                <div>
                                  {replacementProducts?.map(
                                    (product, index) => {
                                      return (
                                        <div
                                          className="selected-product-item"
                                          key={index}
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div>
                                            <img
                                              src={product.images?.[0]?.src}
                                            />
                                            <Label className="form-label">
                                              {product.title}
                                            </Label>
                                          </div>
                                          <i
                                            className="mdi mdi-delete"
                                            style={{
                                              fontSize: 23,
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              setReplacementProducts(
                                                replacementProducts => {
                                                  return replacementProducts
                                                    ? replacementProducts.filter(
                                                        el =>
                                                          el.id !== product.id
                                                      )
                                                    : []
                                                }
                                              )
                                            }}
                                          ></i>
                                        </div>
                                      )
                                    }
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        {selectedOption === "upgrade" && upgradeProduct && (
                          <div className="mb-3 mt-4">
                            <Label className="form-label">
                              Upgrade Product
                            </Label>
                            <div
                              className="selected-product-item"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div>
                                <img
                                  src={upgradeProduct.images?.[0]?.src}
                                  alt="product"
                                />
                                <Label className="form-label">
                                  {upgradeProduct.title}
                                </Label>
                              </div>
                              <i
                                className="mdi mdi-delete"
                                style={{ fontSize: 23, cursor: "pointer" }}
                                onClick={() => setUpgradeProduct(null)}
                              ></i>
                            </div>
                          </div>
                        )}

                        {selectedOption === "replacement" && (
                          <div className="offer-product-container mt-4">
                            <Button
                              color="primary"
                              type="button"
                              data-toggle="modal"
                              data-target="#myModal"
                              onClick={() => {
                                setIsReplacementProducts(true)
                                setIsOpen(true)
                              }}
                            >
                              Select Product
                            </Button>
                          </div>
                        )}

                        {selectedOption === "upgrade" && (
                          <div className="offer-product-container mt-4">
                            <Button
                              color="secondary"
                              type="button"
                              data-toggle="modal"
                              data-target="#myModal"
                              onClick={() => {
                                setIsUpgradedProduct(true)
                                setIsUpgradeModalOpen(true)
                              }}
                            >
                              Select Product
                            </Button>
                          </div>
                        )}
                        {selectedOption === "upgrade" &&
                          upgradeProduct &&
                          upgradeAdditionalProducts?.length > 0 && (
                            <div className="mb-3 mt-4">
                              <Label className="form-label">
                                Add Additional Products to Upgrade
                              </Label>
                              <div>
                                {upgradeAdditionalProducts?.map(
                                  (product, index) => {
                                    return (
                                      <div
                                        className="selected-product-item"
                                        key={index}
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>
                                          <img src={product.images?.[0]?.src} />
                                          <Label className="form-label">
                                            {product.title}
                                          </Label>
                                        </div>
                                        <i
                                          className="mdi mdi-delete"
                                          style={{
                                            fontSize: 23,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setUpgradeAdditionalProducts(
                                              upgradeAdditionalProducts => {
                                                return upgradeAdditionalProducts
                                                  ? upgradeAdditionalProducts.filter(
                                                      el => el.id !== product.id
                                                    )
                                                  : []
                                              }
                                            )
                                          }}
                                        ></i>
                                      </div>
                                    )
                                  }
                                )}
                              </div>
                            </div>
                          )}
                        {selectedOption === "upgrade" && upgradeProduct && (
                          <div className="mb-3 mt-4">
                            {upgradeAdditionalProducts?.length === 0 && (
                              <Label className="form-label">
                                Add Additional Products to Upgrade
                              </Label>
                            )}
                            <div className="offer-product-container mt-4">
                              <Button
                                color="primary"
                                type="button"
                                data-toggle="modal"
                                data-target="#myModal"
                                onClick={() => {
                                  setIsUpgradeAdditionalProducts(true)
                                  setIsModalUpgradeAdditionalProductsOpen(true)
                                }}
                              >
                                Select Product
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {selectedOption === "upgrade" && upgradeProduct && (
                      <>
                        <Col lg={12}>
                          <AutoshipExtraFields
                            validationType={validationType}
                          />
                        </Col>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={12}>
              <div>
                <div className="d-flex flex-wrap gap-2 form-btn">
                  <Button type="submit" color="primary" isLoading={true}>
                    Submit
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add product
          </h5>
          <button
            type="button"
            onClick={() => {
              setIsOpen(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <SearchProducts
            setIsOpen={setIsOpen}
            returnSaveProducts={returnSaveProducts}
            multiProducts={isReplacementProducts}
          />
        </div>
      </Modal>
      <Modal
        isOpen={isUpgradeModalOpen}
        toggle={() => {
          setIsUpgradeModalOpen(!isUpgradeModalOpen)
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="upgradeModalLabel">
            Add product to upgrade
          </h5>
          <button
            type="button"
            onClick={() => {
              setIsUpgradeModalOpen(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <SearchProducts
            setIsOpen={setIsUpgradeModalOpen}
            returnSaveProducts={returnSaveUpgradeProduct}
            multiProducts={false}
          />
        </div>
      </Modal>
      <Modal
        isOpen={isModalUpgradeAdditionalProductsOpen}
        toggle={() => {
          setIsModalUpgradeAdditionalProductsOpen(
            !isModalUpgradeAdditionalProductsOpen
          )
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add product
          </h5>
          <button
            type="button"
            onClick={() => {
              setIsModalUpgradeAdditionalProductsOpen(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <SearchProducts
            setIsOpen={setIsModalUpgradeAdditionalProductsOpen}
            returnSaveProducts={returnSaveUpgradeAdditionalProducts}
            multiProducts={isUpgradeAdditionalProducts}
          />
        </div>
      </Modal>
      <Modal
        isOpen={isFallbackOpen}
        toggle={() => {
          setIsFallbackOpen(!isFallbackOpen)
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add product
          </h5>
          <button
            type="button"
            onClick={() => {
              setIsFallbackOpen(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <SearchProducts
            setIsOpen={setIsFallbackOpen}
            returnSaveProducts={returnSaveFallbackProducts}
            multiProducts={false}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Subscriptions
